import React from 'react'
import { Box, Image, Text, Stack, Flex, Container } from '@chakra-ui/react'
import Logo from '../multimedia/logo.png'
import Logo2 from '../multimedia/logo2.png'

const Layout = (props) => {

    const { children } = props
    return (
        <Box minH={'100vh'} bg={"white"}>
            <Box p={3} mb={6} boxShadow='base' justifyContent={"center"} alignItems={"center"} display={"flex"}>
                <Image src={Logo} w={"auto"} />
            </Box>
            {children}
            <Box
                bg={"black"}
                color={'gray.200'}>
                <Box py={10}>
                    <Flex
                        align={'center'}
                        _before={{
                            content: '""',
                            borderBottom: '1px solid',
                            borderColor: 'gray.200',
                            flexGrow: 1,
                            mr: 8,
                        }}
                        _after={{
                            content: '""',
                            borderBottom: '1px solid',
                            borderColor: 'gray.200',
                            flexGrow: 1,
                            ml: 8,
                        }}>
                        <Image src={Logo2} w={"auto"} />
                    </Flex>
                    <Container as={Stack}
                        maxW={'6xl'}
                        py={4}
                        direction={{ base: 'column', md: 'row' }}
                        spacing={4}
                        justify={{ base: 'center', md: 'space-between' }}
                        align={{ base: 'center', md: 'center' }}>
                        <Text>© 2023 todos los derechos reservados</Text>
                    </Container>
                </Box>
            </Box>
        </Box>
    )
}

export default Layout
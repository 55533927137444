import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Heading, Text, Button } from '@chakra-ui/react'
import Layout from '../layout'

const Error404 = () => {

    return (
        <Layout>
            <br/>
            <br/>
            <Box textAlign="center" py={10} px={6} mt={10}>
                <Heading
                    display="inline-block"
                    as="h2"
                    size="2xl"
                    bg={"green.500"}
                    backgroundClip="text">
                    404
                </Heading>
                <Text fontSize="18px" mt={3} mb={2}>
                    Pagina no se encuentra disponible
                </Text>
                <Text color={'gray.500'} mb={6}>
                    La pagina que buscas no se encuentra disponible
                </Text>
                <Link to="/">
                    <Button
                        colorScheme="green"
                        color="white"
                        variant="solid">
                        Regresar al inicio
                    </Button>
                </Link>
            </Box>
        </Layout>
    )
}

export default Error404
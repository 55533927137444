import React, { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import App from './App'
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react'
import './styles/index.css'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

const theme = extendTheme({
    fonts: {
        heading: `'ReadexPro','NokaTrial', 'Vazirmatn-Bold', Roboto`,
    body: `'Vazirmatn', Roboto`
    }
})

root.render(
    <StrictMode>
        <ChakraProvider theme={theme} >
            <ColorModeScript />
            <App />
        </ChakraProvider>
    </StrictMode>
)

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Error404 from './components/404'
import ErrorBoundary from './components/ErrorBoundary'
import Landing from './pages/Landing'

class App extends React.Component {


    render() {
        return (
            <Router>
                <ErrorBoundary>
                    <Switch>
                        <Route path={"/"} exact component={Landing} />
                        <Route path={"*"} component={Error404} />
                    </Switch>
                </ErrorBoundary>
            </Router >
        )
    }
}

export default App
import { Box, Flex, Image, Text, Heading, List, ListItem, ListIcon, Icon, Avatar } from '@chakra-ui/react'
import Layout from '../layout'
import sv from '../multimedia/sv.png'
import hn from '../multimedia/hn.png'
import foto1 from '../multimedia/foto1.jpg'
import foto2 from '../multimedia/foto2.jpg'
import foto3 from '../multimedia/foto3.jpg'
import foto4 from '../multimedia/foto4.jpg'
import icono0 from '../multimedia/ICONO DE CARRO CO2.png'
import icono1 from '../multimedia/ICONO 1.png'
import icono2 from '../multimedia/ICONO 2.png'
import icono3 from '../multimedia/ICONO 3.png'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import MiniCard from '../components/MiniCard'

const SvgCircle = (props) => {
    return (
        <Icon viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" className={props.className}>
            <path
                fill='currentColor'
                d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
            />
        </Icon>
    )
}

const Landing = () => {

    return (
        <Layout>
            <Flex flexDir={"column"} margin={"auto"} maxW={"1200px"} mt={"3.5rem"} alignItems={"flex_start"} p={6}>
                <Box>
                    <Heading >Descubre Easycar, ¡La compañía para comprar vehículos!</Heading>
                </Box>
                <Text as={"b"}>Selecciona tu país:</Text>
            </Flex>
            <Flex flexDir={{ base: 'column', md: 'row' }} margin={"auto"} maxW={"400px"} mt={"20px"} mb={"3.5rem"} justifyContent={"space-around"} alignItems={"center"}>
                <a href='https://easycar.com.sv/'>
                    <Box className='miniCarIcon'>
                        <Image src={sv} height={{ base: 'auto', md: "80px" }} width={{ base: "80px", md: 'auto' }} m={"auto"} />
                        <br />
                        <Text as={"b"} fontSize={"xl"} m={"auto"}>El Salvador</Text>
                    </Box>
                </a>
                <a href='https://easycar.com.hn/'>
                    <Box className='miniCarIcon'>
                        <Image src={hn} height={{ base: 'auto', md: "80px" }} width={{ base: "80px", md: 'auto' }} m={"auto"} />
                        <br />
                        <Text as={"b"} fontSize={"xl"} m={"auto"}>Honduras</Text>
                    </Box>
                </a>
            </Flex>

            <Box bg={'gray.100'} w={"full"}>
                <Box maxW={"1400px"} mt={"7.5rem"} p={4} m={"auto"}>
                    <Heading pt={"3rem"}>Apostamos a una economía circular</Heading>
                </Box>
                <Flex maxW={"1400px"} mt={"7.5rem"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>

                    <Flex flex={1} flexDir={{ base: 'column', md: 'row' }} alignItems={"center"} justifyContent={"center"} margin={"auto"} p={"10"} gap={3}>
                        <Image alt={'Nuestra historia'} height={"auto"} width={'100%'} maxW={"300px"} src={icono0} />
                        <Box minWidth={"300px"} m={"auto"}>
                            <List spacing={3}>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={SvgCircle} color='green.500' /> Reducimos de 10-12 Toneladas la emisión de CO2, al comercializar vehículos seminuevos.</ListItem>
                                <ListItem display={"flex"} alignItems={"center"}><ListIcon as={SvgCircle} color='green.500' />Ahorramos un total de 42,324 toneladas de CO2 desde nuestra fundación al cierre del 2024</ListItem>
                            </List>
                        </Box>
                    </Flex>
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Text textAlign={"justify"} >
                            Al ofrecer autos seminuevos de calidad, <b>contribuimos al Objetivo de Desarrollo Sostenible 13</b>, que se centra en la acción por el clima. Promovemos la reutilización y el reciclaje de vehículos, lo que ayuda a reducir la necesidad de producir autos nuevos y, por lo tanto, disminuye las emisiones de CO2 asociadas con su fabricación.
                            <br /><br />
                            Al elegir un auto seminuevo, nuestros clientes no solo obtienen un vehículo confiable, sino que también participan activamente en la lucha contra el cambio climático, reduciendo su huella de carbono y apoyando prácticas de consumo más sostenibles.
                        </Text>
                    </Box>
                </Flex>
            </Box>

            <Box w={"full"}>
                <Flex maxW={"1400px"} mt={"7.5rem"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"} p={"10"}>
                        <Box>
                            Recomendamos encarecidamente que, al momento de adquirir un vehículo con nosotros, nuestros clientes prefieran uno que cuente con seguridad pasiva como <b>Bolsas de Aire en perfecto estado y Cinturones de Seguridad Funcionales</b>, los cuales podrán activarse de manera correcta y oportuna en un accidente vial y reducirán el riesgo de sufrir un accidente fatal.
                        </Box>
                        <Image alt={'Acerca de nosotros'} height={"auto"} width={'100%'} src={foto4} />
                    </Box>
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading textAlign={"right"}>Aportamos al desarrollo de aspectos en pro de la comunidad</Heading>
                        <br /><br />
                        <Text>
                            Al contar con esta estrategia aportamos al desarrollo de aspectos en pro de la comunidad como:
                        </Text>
                        <Flex flexDir={{ base: 'column', lg: 'row' }} alignItems={"start"} justifyContent={"space-around"} pt={"10px"} pb={"10px"} gap={3}>
                            <MiniCard
                                icon={<Avatar className='miniCarIcon' bg={"#fff"} size='xl' icon={<Image src={icono1} height={"50px"} />} />}
                                title={"ODS 3: Salud y Bienestar"}
                                content={"Contribuimos a reducir las lesiones y muertes causadas por accidentes de tráfico, promoviendo la salud y el bienestar de sus clientes y de la comunidad en general."}
                            />

                            <MiniCard
                                icon={<Avatar className='miniCarIcon' bg={"#fff"} size='xl' icon={<Image src={icono2} height={"50px"} />} />}
                                title={"ODS 11: Ciudades y Comunidades Sostenibles"}
                                content={" Apoyamos el desarrollo de sistemas de transporte seguros, que es una parte clave de crear ciudades y comunidades sostenibles."}
                                titleColor={"green"}
                            />

                            <MiniCard
                                icon={<Avatar className='miniCarIcon' bg={"#fff"} size='xl' icon={<Image src={icono3} height={"50px"} />} />}
                                title={"ODS 12: Producción y Consumo Responsables"}
                                content={" Al enfatizar la importancia de la seguridad en los vehículos, fomentamos  un consumo responsable para la toma de decisiones informadas basadas en la seguridad y la eficiencia del vehículo."}
                            />

                        </Flex>
                    </Box>
                </Flex>
            </Box>

            <Box bg={'gray.100'} w={"full"}>
                <Flex maxW={"1400px"} mt={"7.5rem"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} order={{ base: 2, md: 1 }} p={"10"} margin={"auto"} >
                        <Heading> Nuestra historia</Heading>
                        <br />
                        <Text textAlign={"justify"} >
                            En 2021 nació la marca EASYCAR con el propósito de brindar una solución financiera a muchos segmentos de la sociedad que desean y anhelan adquirir un vehículo. EASYCAR abrió sus puertas en la ciudad de San Salvador del país El Salvador y en la ciudad de Tegucigalpa del país Honduras haciendo realidad nuestras primeras dos agencias EASYCAR.
                            <br /><br />
                            Nuestro primer año de mercado fue un éxito y logramos un crecimiento de más de 500 clientes en tan solo 6 meses de estar en el mercado, por nuestras soluciones financieras ágiles y en comprensión a nuestros diferentes segmentos de negocio como comerciantes y microempresarios nos hemos convertido en sus aliados estratégicos.
                            <br /><br />
                            Entre 2022 y 2024 hemos aperturado dos agencias más en El Salvador para favorecer a todos los habitantes de los departamentos de SANTA ANA y SAN MIGUEL y en Honduras los lugares beneficiados fueron CHOLUTECA, COMAYAGUA Y SAN PEDRO SULA, esta es una muestra de nuestro crecimiento y compromiso para brindar los mejores servicios a nuestros clientes.
                            <br /><br />
                            En 2024, EASYCAR apertura su primer taller automotriz EASY MOTORS, ofreciendo servicios especializados para el mantenimiento preventivo y correctivo.
                            <br /><br />
                            Fue este mismo año que EASYCAR se transformó en una marca con responsabilidad social, cuyo objetivo se basa en 2 pilares fundamentales, la seguridad pasiva y la economía circular.
                            <br /><br />
                            EASYCAR nace con el producto principal financiero enfocado en brindar oportunidades para que puedas adquirir el vehículo que tanto soñaste y permitir a muchos empresarios y emprendedores lograr construir en conjunto nuestro futuro y es por eso por lo que ahora contamos con más de 1,500 CLIENTES felices con nuestros servicios y de los cuales nos sentimos orgullosos de que sean parte de la familia EASYCAR.
                        </Text>
                    </Box>
                    <Box flex={1} order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} margin={"auto"} p={"10"}>
                        <Image alt={'Nuestra historia'} height={"auto"} width={'100%'} src={foto1} />
                    </Box>
                </Flex>
            </Box>

            <Box w={"full"}>
                <Flex maxW={"1400px"} mt={"7.5rem"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} alignItems={"center"} justifyContent={"center"} margin={"auto"} p={"10"}>
                        <Image alt={'Acerca de nosotros'} height={"auto"} width={'100%'} src={foto2} />
                    </Box>
                    <Box flex={1} p={"10"} margin={"auto"} >
                        <Heading>Acerca de nosotros</Heading>
                        <br /><br />
                        <Text as={"b"}>Misión</Text>
                        <Text textAlign={"justify"}>
                            Somos una empresa especializada en el financiamiento para la compra de vehículos cuyo objetivo es crear la experiencia de obtener un auto de manera memorable e incréible para nuestros clientes, contamos con asesoría personalizada y un equipo de trabajo altamente comprometido y alineado a nuestros valores éticos y morales
                        </Text>
                        <br />
                        <Text as={"b"}>Visión</Text>
                        <Text textAlign={"justify"}>
                            Ser reconocidos como una marca confiable, ágil y eficiente que garantiza a sus clientes internos y externos la excelencia en el servicio, transformando la industria del financiamiento para compra de vehículos a nivel regional con innovación de nuevos productos y herramientas de alta tecnología.
                        </Text>
                        <Box mt={6}>
                            <Box roundedBottom={15} bgColor={"#349141"} color={"white"} textAlign={"center"} maxW={"400px"} m={"auto"}>
                                <Text fontSize='2xl' m={"auto"}>Nuestros Valores</Text>
                            </Box>
                            <Flex justify={"space-around"} m={"6"}>
                                <Box fontSize={"xl"}>
                                    <List spacing={3}>
                                        <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Respeto</ListItem>
                                        <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Integridad</ListItem>
                                        <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Cooperación Creativa</ListItem>
                                    </List>
                                </Box>
                                <Box fontSize={"xl"}>
                                    <List spacing={3}>
                                        <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Confianza</ListItem>
                                        <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Proactividad</ListItem>
                                        <ListItem display={"flex"} alignItems={"center"}><ListIcon as={IoMdCheckmarkCircle} color='green.500' />Excelencia de Servicio</ListItem>
                                    </List>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                </Flex>
            </Box>

            <Box bg={'gray.100'} w={"full"}>
                <Flex maxW={"1400px"} mt={"7.5rem"} flexDir={{ base: 'column', md: 'row' }} p={4} m={"auto"}>
                    <Box flex={1} order={{ base: 2, md: 1 }} p={"10"} margin={"auto"} >
                        <Heading>Nuestro futuro</Heading>
                        <br />
                        <Text textAlign={"justify"}>
                            Nuestro actual producto financiero ha permitido que muchos segmentos de la sociedad puedan hacer realidad que sus negocios funcionen y prosperen, lo cual para EASYCAR es un enorme orgullo y es por ello que dentro de nuestras capacitaciones motivamos a nuestros colaboradores para que siempre nuestros clientes sean prioridad y para ello nuestro personal es VITAL.
                            <br /><br />
                            Es por nuestros más de 1,500 clientes entre los que se encuentran emprendedores, comerciantes, micro empresarios, transportistas, profesionales independientes, colaboradores del sector privado y público, agricultores, sector de pesca y ganado y diferentes segmentos de negocio que hemos beneficiado con nuestro producto que podemos decir que EASYCAR viene por más...
                            <br /><br />
                            Prepárate, que estamos comprometidos en brindarte cada día una mejor experiencia para la adquisición de tu propio vehículo.
                        </Text>
                    </Box>
                    <Box flex={1} order={{ base: 1, md: 2 }} alignItems={"center"} justifyContent={"center"} p={"10"} margin={"auto"}>
                        <Image alt={'nuestro futuro'} height={"auto"} width={'100%'} src={foto3} />
                    </Box>
                </Flex>
            </Box>
        </Layout>
    )
}

export default Landing